import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AsciinemaSelf from '../components/AsciinemaSelf'

const InitializationPage = () => (
  <Layout>
    <SEO title="Initialization" />
    <h1>Initialization</h1>
    <p>This is how this site was originally initialized.</p>
    <h2> Initial Build </h2>
    <p> Here is an asciinema show of how this scaffolding site was built initially.</p>
    <AsciinemaSelf localpath='../assets/sass-test.rec' />
    <Link to="/">Go back to the homepage</Link>
    <Link to="/initialize/">Go to the initialization page to learn all about how this site was originally built.</Link> <br />
  </Layout>
)

export default InitializationPage
